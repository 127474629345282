.outerContainer{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.quitLayer{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  align-items: center;


}
.blurScreen{
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: .7;

}
.quitOptions{
  width: 100%;
  max-width: 300px;
  display: flex;
  position: absolute;
  flex-direction: column;
  height: 200px;
  background-color: white;
  border: 2px solid;
  opacity: 1;
  z-index: 4;
  justify-content: space-around;

}
.quitButtonHolder{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.quitMessage{
  font-weight: bold;
  font-size: 32px;


}
.buttonStyle{
  height: 38px;
  border-radius: 12px;
  border:3px solid;
  border-color: darkblue;
  color: darkblue;
  font-size: 18px;
  font-weight: bold;
  /* cursor: pointer; */

}
.buttonStyle:active{
  background-color: darkblue;
  color:white;
}
.getQuitHolder{
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.quitButton{

    width: 200px;
    background-color: #878078;
    color: white;
    font-size: 24px;
    padding: 5px;
    margin: 5px;


}
.moveButton{
  width: 180px;
  height: 40px;
  font-size: 22px;
  color:blue;
  border-radius: 10px;
  border: 2px solid blue;
  background-color: white;


}
.moveButton:disabled{
  opacity: .3;
}
.moveButton:enabled:active{
  background-color: blue;
  color: white;


}
