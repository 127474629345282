.outerContainer{
  width: 100%;
  height: 100%;
  position: absolute;

}
.gameoveroption{
  width: 200px;
  background-color: #878078;
  color: white;
  font-size: 24px;
  padding: 5px;
  margin: 5px;
}
