.outerContainer{
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
.outerContainer p{
  text-align: left;
  margin:5px;
}
.outerContainer h2{
  margin: 3px;
}
.backHolder{
  width: 100%;
  display: flex;
}
.menuButton{

}
