.outerContainer{
  width: 100%;
  max-width: 600px;
  height: 600px;
  margin-left: auto;
  margin-right: auto;
  
  display: flex;
  justify-content: center;
}
