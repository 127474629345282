.outerContainer{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  max-height: 400px;
  align-items: center;
}

.gameTitle{
  font-size: 50px;
}
.gameSubtitle{
  font-size: 18px;
}

.menuButton{
  width: 100%;
  height: 60px;
}
.buttonHolder{
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  align-items: center;
}

.buttonStyle{
  width: 200px;
  background-color: #878078;
  color: white;
  font-size: 24px;
  padding: 5px;
  margin: 5px;

}
.buttonStyle:disabled{
  opacity: .6
}
